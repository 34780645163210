<template>
  <div
    class="justify-center w-full h-full d-flex align-center login-hero"
    style="background: white"
  >
    <v-img
      style="z-index: 3"
      alt="Company Logo"
      :class="{'login-logo':$vuetify.breakpoint.mdAndUp, 'login-logo-mobile': $vuetify.breakpoint.smAndDown}"
      :src="require('@/../public/img/logo.png')"
      width="140"
      contain
    />

    <v-card
      style="z-index: 2"
      class="pa-4"
      elevation="0"
    >
      <v-card-text class="d-flex flex-column align-center registration-container content">
        <div class="mt-4 text-center">
          <h1 class="display-1 black--text font-weight-black mb-4">
            Herzlich Willkommen
          </h1>
          <p class="mb-8 text-body-2 black--text">
            Geben Sie Ihren zuvor im CRM erstellten API-Key ein, um direkt loslegen zu können.
          </p>
        </div>
        <div
          class="mt-4"
          :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'450px'}"
        >
          <v-form
            ref="signupForm"
            @submit="submitToken"
          >
            <v-text-field
              v-model="token"
              label="API-Key"
              outlined
              hide-details="auto"
              class="mb-4"
              :rules="[rules.required]"
              required
              validate-on-blur
            />

            <div
              class="px-0 d-flex flex-column"
            >
              <v-expand-transition>
                <v-alert
                  v-if="error"
                  type="error"
                  dense
                  tile
                  text
                  class="my-4"
                >
                  <span v-html="getErrorMessage(error)" />
                </v-alert>
              </v-expand-transition>
              <loading-button
                color="primary"
                type="submit"
                class="mb-5"
                :loading="submitting"
                :disabled="!token"
              >
                Jetzt loslegen
              </loading-button>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import featureMixin from '@/mixins/feature'
import brandingMixin from '@/mixins/branding'
import { required } from '@/lib/validation'
import VALIDATE_SIGNUP_TOKEN from './ValidateSignUpToken.gql'

export default {
  components: {
    LoadingButton: () => import('@/components/loading-button')
  },
  mixins: [featureMixin, brandingMixin],
  props: {
    partner: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      submitting: false,
      token: '',
      error: null
    }
  },
  computed: {
    errorMessages () {
      return {
        invalidToken: 'Der API-Key ist ungültig. Bitte wenden Sie sich an den Herausgeber des API-Key.',
        unknown: 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
      }
    }
  },
  created () {
    this.rules = { required }
  },
  mounted () {
    this.token = this.$route.params.token
    if (this.token) this.submitToken()
  },
  apollo: {
    $client: 'public'
  },
  methods: {
    async submitToken (event) {
      if (event)event.preventDefault()
      this.error = null

      this.submitting = true

      try {
        const { data: { validateSignUpToken: token } } = await this.$apollo.mutate({
          mutation: VALIDATE_SIGNUP_TOKEN,
          variables: {
            input: {
              token: this.token,
              partner: this.partner
            }
          }
        })

        this.submitting = false
        this.$router.push({ name: 'signup', params: { token } })
      } catch (error) {
        if (error?.message?.match(/Invalid token/)) {
          this.error = 'invalidToken'
        } else {
          this.error = 'unknown'
        }
        this.submitting = false
      }
    },
    getErrorMessage (code) {
      return this.errorMessages[code] || this.errorMessages.unknown
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 450px;
}

.login-hero{
  position: relative;
  height: 100%;
}

.login-logo{
  position: absolute;
  top:0;
  left:0;
  margin-top:30px;
  margin-left:30px;
  z-index: 2;
}

.login-logo-mobile{
  position: absolute;
  top:0;
  margin-top:30px;
  left:auto;
  right:auto;
  z-index: 2;
}

</style>
